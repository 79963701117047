.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-1);
  height: 100%;
  justify-content: center;
}

.tokenButton {
  display: flex;
  border-radius: 6px;
  padding: 0px var(--space-1) 0px var(--space-1);
  background-color: var(--color-border-background);
  margin: var(--space-1);
  height: 30px;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  margin-left: 0;
  margin-right: 0;
}
