.item {
  position: relative;
  padding: 8px 24px;
}

.item:not(:last-of-type):after {
  content: '';
  background: var(--color-border-background);
  position: absolute;
  bottom: 0;
  left: 24px;
  height: 2px;
  width: calc(100% - 48px);
}

.requiresAction {
  background-color: var(--color-primary-background);
}

.avatar {
  min-width: 42px;
}

.secondaryText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-border-main);
}
