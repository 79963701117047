.dialog :global .MuiDialogActions-root {
  border-top: 2px solid var(--color-border-light);
  padding: var(--space-3);
}

.dialog :global .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.dialog :global .MuiDialogActions-root:after {
  content: '';
  order: 1;
  flex: 1;
}

.dialog :global .MuiDialogTitle-root {
  border-bottom: 2px solid var(--color-border-light);
}

@media (min-width: 600px) {
  .dialog :global .MuiDialog-paper {
    min-width: 600px;
    margin: 0;
  }
}
