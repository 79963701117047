.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  font-size: 12px;
}

.inlineIndicator {
  display: inline-block;
  min-width: 70px;
  font-size: 11px;
  line-height: normal;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
}
