.menu :global .MuiPaper-root {
  border-radius: 8px !important;
}

.menu :global .MuiList-root {
  padding: 4px;
}

.menu :global .MuiMenuItem-root {
  padding-left: 12px;
  min-height: 40px;
  border-radius: 8px !important;
}

.menu :global .MuiMenuItem-root:hover {
  background-color: var(--color-secondary-background);
}

.menu :global .MuiListItemIcon-root {
  min-width: 26px;
}
